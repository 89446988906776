import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Divider, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import { PageLayout } from "../components/page-layout";
import {
  callShipsAPI,
  callCruiseLinesAPI,
} from "../services/reference-api.service";
import { FormControl } from "@mui/material";

const image_url_root = process.env.REACT_APP_IMAGE_URL_ROOT;

export const ShipsPage = () => {
  const [message, setMessage] = useState("");
  const [ships, setShips] = useState([]);
  const [cruiseLine, setCruiseLine] = useState("");
  const [cruiseLines, setCruiseLines] = useState([]);

  const handleCruiselineChange = (event) => {
    setCruiseLine(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const getShips = async () => {
      const { data, error } = await callShipsAPI();

      if (!isMounted) {
        return;
      }

      if (data) {
        setShips(data);
        setMessage("");
      }

      if (error) {
        setShips([]);
        setMessage(error.message);
      }
    };

    const getCruiseLines = async () => {
      const { data, error } = await callCruiseLinesAPI();

      if (!isMounted) {
        return;
      }

      if (data) {
        setCruiseLines(data);
      }

      if (error) {
        setCruiseLines([]);
        setMessage(error.message);
      }
    };

    getShips();
    getCruiseLines();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <div className="content-layout">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <Typography
              id="page-title"
              className="content__title"
              variant="h2"
              sx={{ padding: "14px" }}
            >
              Ships
            </Typography>
            <Card sx={{ display: "flex", width: "100%" }}>
              <CardContent sx={{ flex: "1 0 auto", pb: "0px" }}>
                <FormControl sx={{ minWidth: 240, fontSize: "1.5rem" }}>
                  <InputLabel htmlFor="cruise-line">Cruise line</InputLabel>
                  <Select
                    id="cruise-line"
                    value={cruiseLine}
                    label="Cruise line"
                    onChange={handleCruiselineChange}
                    sx={{ fontSize: "1.5rem" }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {cruiseLines.map((cruiseLine) => (
                      <MenuItem
                        key={cruiseLine.lineid}
                        value={cruiseLine.lineid}
                      >
                        {cruiseLine.companyname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Stack>
          <Divider
            sx={{ background: "var(--brand-gradient)", height: "4px" }}
          />
          {message && <div className="error-message">{message}</div>}
          <Grid container spacing={2}>
            {ships.map((ship) => (
              <Grid item key={ship.shipid} size={3}>
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h4">{ship.shipname}</Typography>
                    }
                    subheader={
                      <Typography variant="h5">{ship.companyname}</Typography>
                    }
                  />
                  {/* <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {ship.shipdescription}
                    </Typography>
                  </CardContent> */}
                  <CardMedia
                    sx={{ height: 200 }}
                    image={image_url_root + ship.photoUrl}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </div>
    </PageLayout>
  );
};
