import axios from "axios";

export const callShipsAPI = async (filterOptions) => {
  try {
    const API_URL = process.env.REACT_APP_DATA_SERVER_URL;
    console.log("API_URL", API_URL);
    let url = `${API_URL}api/getShips`;
    if (filterOptions && filterOptions.lineid) {
      url += `?lineid=${filterOptions.lineid}`;
    } else {
      url += `?lineid=0`;
    }
    console.log("url", url);

    const response = await axios.get(url);

    // if (filterOptions) {

    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      // [
      //   {
      //     "shipid": 1,
      //     "shipname": "Caribbean Princess",
      //     "classid": 1,
      //     "tonnage": 112894,
      //     "passengers": 3114,
      //     "lineid": 1,
      //     "companyname": "Princess"
      //   }
      // ]

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};

// getCruiselines
export const callCruiseLinesAPI = async () => {
  try {
    const API_URL = process.env.REACT_APP_DATA_SERVER_URL;
    console.log("API_URL", API_URL);
    let url = `${API_URL}api/getCruiseLines`;

    const response = await axios.get(url);

    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      // [
      //   {
      //     "lineid": 1,
      //     "companyname": "Princess",
      //     "logoimage": ""
      //   }
      // ]

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};
