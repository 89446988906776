import { Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="https://stockimages3fhuifjkcn389.blob.core.windows.net/branding/logo1-icon.png"
          alt="Ship logo"
          // width="122"
          height="65"
        />
      </NavLink>
      <Typography
        className="nav-bar__title"
        variant="h3"
        sx={{ color: "#ffffff", paddingLeft: "10px" }}
      >
        Ship Shows
      </Typography>
    </div>
  );
};
